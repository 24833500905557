/* .border-end {
    height:100px;
    width:40px !important;
    background-color:#065d62;
   
} */
.boxx{
    height:100px;
    width:100px !important;
    background-color:#065d62;
    box-shadow: 0px 4px 12px black;


 
}
.hed{
    color:black;
    font-weight: bold;
    font-size: 80px !important;
}