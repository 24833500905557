
.mid-horizental{
    background-color: black;
    margin-bottom:20px;
}
.main-col{
    height:560px;
    width:260px !important;
    background-color: #065d62;
    /* border:5px solid black; */
    box-shadow: 0px 3px 15px 2px black;
    border-radius: 12px;
     padding-right:20px !important;
    /* margin-left: 10px !important;  */
  
}
.top{
    height:80px;
    width:230px;
    background-color: #a8f1f5!important;
    /* padding-left:10px !important;
    */
}
 .mid{
    height:200px;
    width:230px;
    background-color: #a8f1f5!important;
 } 
 .bottom{
    height:200px;
    width:230px;
    background-color: #a8f1f5!important;
    color:black;
    font-style: inherit;
    font-size:10px !important;
 } 
    
   
   
