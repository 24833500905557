
.box img{
    height: 250px;
    width: 280px;
    border: 10px solid #065d62;
    background: green;
    box-sizing: border-box;
    background-size: cover;
    /* box-shadow: 0px 0px 3px 5px solid white; */
    box-shadow: 0px 3px 15px 2px #065d62;
    border-radius:10px !important;
   
}
